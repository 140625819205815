import React from 'react';
import { Routes, BrowserRouter, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Nopage from './pages/Nopage';
import SendRequest from './pages/SendRequest';
import AllRequests from './pages/AllRequests';
import DeviceDetails from './pages/DeviceDetails';

function App() {
  const isLoggedIn = window.localStorage.getItem("loggedIn");
  return (
    <div>
      <BrowserRouter>
        <AuthProvider>
          {/* {isLoggedIn === "true" && <Sidebar /> || <Header />} */}

          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/login" element={<Home />} />
            <Route
              path="/dashboard"
              element={
                isLoggedIn === "true" ? <Dashboard /> : <Navigate to="/login" />
              }
            />
            <Route path="/send-request" element={isLoggedIn === "true" ? <SendRequest /> : <Navigate to="/login" />} />
            <Route path="/all-requests" element={isLoggedIn === "true" ? <AllRequests /> : <Navigate to="/login" />} />
            <Route path="/device-details/:deviceId" element={isLoggedIn === "true" ? <DeviceDetails /> : <Navigate to="/login" />} />

            <Route path="*" element={<Nopage />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
