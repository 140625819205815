import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUser,
    faGaugeHigh,
    faCartShopping,
    faAward,
    faRightFromBracket
} from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
    const { logout } = useAuth();
    // const navigate = useNavigate();

    // const handleLogout = () => {
    //     logout();
    //     localStorage.removeItem('token');
    //     localStorage.setItem("loggedIn", "false");
    //     window.location.reload("/");
    // };

    const handleLogout = async () => {
        try {
            logout();
            localStorage.removeItem('authtoken');
            localStorage.setItem("loggedIn", "false");
            window.location.reload("/");
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (

        <nav id="sidebar">
            <div className="sidebar-header">
                <h3>Bootstrap Sidebar</h3>
            </div>

            <ul className="list-unstyled components">
                <li>
                    <Link to="/dashboard" aria-expanded="false">
                        <div className="nav_icon_small">
                            <FontAwesomeIcon icon={faGaugeHigh} />
                        </div>
                        <div className="nav_title">
                            <span>Dashboard </span>
                        </div>
                    </Link>
                </li>

                <li>
                    <Link to="/send-request" aria-expanded="false">
                        <div className="nav_icon_small">
                            <FontAwesomeIcon icon={faCartShopping} />
                        </div>
                        <div className="nav_title">
                            <span>Send Request </span>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/all-requests" aria-expanded="false">
                        <div className="nav_icon_small">
                            <FontAwesomeIcon icon={faAward} />
                        </div>
                        <div className="nav_title">
                            <span>All Requests</span>
                        </div>
                    </Link>
                </li>
                {/* <li>
                    <Link to="/technician-register" aria-expanded="false">
                        <div className="nav_icon_small">
                            <FontAwesomeIcon icon={faUser} />
                        </div>
                        <div className="nav_title">
                            <span>Technician Register</span>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/vendor-register" aria-expanded="false">
                        <div className="nav_icon_small">
                            <FontAwesomeIcon icon={faUser} />
                        </div>
                        <div className="nav_title">
                            <span>Vendor Requests</span>
                        </div>
                    </Link>
                </li> */}
                {/* <li>
                    <Link to="/warranty-progress" aria-expanded="false">
                        <div className='nav_icon_small'>
                            <FontAwesomeIcon icon={faUser} />
                        </div>
                        <div className="nav_title">
                            <span>

                                Warranty In Progress

                            </span>
                        </div>
                    </Link>
                </li> */}

                {/* <li>
                    <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">Pages</a>
                    <ul className="collapse list-unstyled" id="pageSubmenu">
                        <li>
                            <a href="#">Page 1</a>
                        </li>
                        <li>
                            <a href="#">Page 2</a>
                        </li>
                        <li>
                            <a href="#">Page 3</a>
                        </li>
                    </ul>
                </li> */}

            </ul>

            <ul className="list-unstyled CTAs">
                <li>
                    <Link to="#" aria-expanded="false" onClick={handleLogout} className="download">
                        <div className="nav_icon_small">
                            <FontAwesomeIcon icon={faRightFromBracket} />
                        </div>
                        <div className="nav_title">
                            <span>Logout </span>
                        </div>
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default Sidebar;

