import React, { useState, useEffect } from 'react';
import Sidebar from '../components/sidebar';
import Header from '../components/header';
import { Link } from 'react-router-dom';

const AllRequests = () => {
    const [requests, setRequests] = useState([]);
    const [devices, setDevices] = useState({});
    const [activeRequestId, setActiveRequestId] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setRequests(requests => requests.map(request => ({ ...request, collapsed: false })));
        getAllRequests();
    }, []);

    const getAllRequests = async () => {
        const token = window.localStorage.getItem('token');

        try {
            const response = await fetch("https://api.intimeglobal.ae/userRequest", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': `Bearer ${token}`,
                }
            });

            const data = await response.json();

            if (data.status === "ok") {
                setRequests(data.data);
                setLoading(false);
                // Fetch devices for each request
            } else {
                console.log("Error Showing")
            }
        } catch (error) {
            console.error("Something went wrong:", error);
        }
    }

    const getAllDevices = async (requestID) => {
        console.log(requestID);
        const token = window.localStorage.getItem('token');

        setActiveRequestId(requestID);

        try {
            const response = await fetch(`https://api.intimeglobal.ae/getUserDevices/${requestID}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': `Bearer ${token}`,
                }
            });
            const data = await response.json();
            if (data.status === "ok") {
                // Update devices state with devices for the current request ID
                setDevices(prevDevices => ({
                    ...prevDevices,
                    [requestID]: data.data
                }));
            } else {
                console.log("Error Showing")
            }
        } catch (error) {
            console.error("Something went wrong:", error);
        }
    }

    return (
        <div className="wrapper">
            <Sidebar />
            <div id="content">
                <Header />
                <div>
                    <div className="row">
                        <div className="col-xl-12">
                            {(requests.length > 0) && requests.map((request, index) => (
                                <div class="accordion" id="accordionExample" key={request.requestID}>
                                    <div class="card">
                                        <div class="card-header" id="headingOne">
                                            <h2 class="mb-0">
                                                <button
                                                    class="btn btn-link btn-block text-left text-dark"
                                                    type="button"
                                                    data-toggle="collapse"
                                                    data-target={`#collapse${index}`}
                                                    aria-expanded={activeRequestId === request.requestID}
                                                    aria-controls={`collapse${index}`}
                                                    onClick={() => getAllDevices(request.requestID)}
                                                >
                                                    <strong>Request Id:</strong>&nbsp; {request.requestID.split("-")[0]}
                                                </button>
                                            </h2>
                                        </div>

                                        <div
                                            id={`collapse${index}`}
                                            class={`collapse ${activeRequestId === request.requestID ? 'show' : ''}`}
                                            aria-labelledby={`heading${index}`}
                                            data-parent="#accordionExample"
                                        >
                                            <div class="card-body">
                                                <table className="table table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Device Id</th>
                                                            <th>Model Name</th>
                                                            <th>Model No</th>
                                                            <th>Color</th>
                                                            <th>Memory</th>
                                                            <th>Serial</th>
                                                            <th>IMEI 1</th>
                                                            <th>IMEI 2</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {devices[request.requestID] && devices[request.requestID].map((device, index) => (
                                                            <tr key={index}>
                                                                <td>{device.deviceinfo.deviceId}</td>
                                                                <td>{device.deviceinfo.devicedetails.modelname}</td>
                                                                <td>{device.deviceinfo.devicedetails.modelno}</td>
                                                                <td>{device.deviceinfo.devicedetails.color}</td>
                                                                <td>{device.deviceinfo.devicedetails.memory}</td>
                                                                <td>{device.deviceinfo.devicedetails.serial}</td>
                                                                <td>{device.deviceinfo.devicedetails.imei1}</td>
                                                                <td>{device.deviceinfo.devicedetails.imei2}</td>
                                                                <td>
                                                                    {device.deviceinfo.diagnoseDone === "completed" ? (
                                                                        <div>
                                                                            <Link to={`/device-details/${device.deviceinfo.deviceId}`}>
                                                                                Show Device Details
                                                                            </Link>
                                                                        </div>
                                                                    ) : null}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllRequests;

