import React, { useEffect, useState } from 'react';
import Sidebar from '../components/sidebar';
import Header from '../components/header';
import { useParams } from 'react-router-dom';


const DeviceDetails = () => {
    const [loading, setLoading] = useState(true);
    const [device, setDevice] = useState({});
    // const [deviceID, setDeviceID] = useState('');
    const { deviceId } = useParams();
    console.log(deviceId);

    useEffect(() => {
        if (deviceId) {
            getEndDeviceDetails(deviceId);
        }
    }, []);

    const getEndDeviceDetails = async (id) => {
        const token = window.localStorage.getItem('token');
        try {
            const response = await fetch(`https://api.intimeglobal.ae/getUserDevicedetails/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (data.status === "ok") {
                setDevice(data.data);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user data:', error);
            setLoading(false);
        }
    }

    return (
        <div className="wrapper">
            <Sidebar />
            <div id="content">
                <Header />
                <div>
                    <div className="row">
                        <div className="col-xl-12">
                            {Object.keys(device).length > 0 ?
                                <div className="deviceid">
                                    <table width="100%" cellpadding="5" cellspacing="10">
                                        <tr>
                                            <td>
                                                <h4>Device Details</h4>
                                            </td>
                                            <td className="px-4">
                                                <h4>Functional Diagnostics</h4>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="align-top">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <tr>
                                                        <td>Device Id</td>
                                                        <td>{device.deviceinfo.deviceId}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Warranty Days Left</td>
                                                        <td> {device.deviceinfo.claimwarranty ? 365 - (Math.floor((new Date() - new Date(device.deviceinfo.claimwarrantyDate)) / (1000 * 60 * 60 * 24))) : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Model Name</td>
                                                        <td>{device.deviceinfo.devicedetails.modelname}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Model No</td>
                                                        <td>{device.deviceinfo.devicedetails.modelno}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Color</td>
                                                        <td>{device.deviceinfo.devicedetails.color}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Memory</td>
                                                        <td>{device.deviceinfo.devicedetails.memory}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Carrier</td>
                                                        <td>{device.deviceinfo.devicedetails.carrier}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Serial</td>
                                                        <td>{device.deviceinfo.devicedetails.serial}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IMEI 1</td>
                                                        <td>{device.deviceinfo.devicedetails.imei1}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IMEI 2</td>
                                                        <td>{device.deviceinfo.devicedetails.imei2}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Firmware</td>
                                                        <td>{device.deviceinfo.devicedetails.firmware}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Version</td>
                                                        <td>{device.deviceinfo.devicedetails.version}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>OS</td>
                                                        <td>{device.deviceinfo.devicedetails.os}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sim Lock</td>
                                                        <td>{device.deviceinfo.devicedetails.simlock}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Jail Break</td>
                                                        <td>{device.deviceinfo.devicedetails.jailbreak}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Region Code</td>
                                                        <td>{device.deviceinfo.devicedetails.regioncode}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Battery Serial</td>
                                                        <td>{device.deviceinfo.devicedetails.batteryserial}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Battery Health</td>
                                                        <td>{device.deviceinfo.devicedetails.batteryhealth}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Design Capacity</td>
                                                        <td>{device.deviceinfo.devicedetails.designcapacity}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Current Capacity</td>
                                                        <td>{device.deviceinfo.devicedetails.currentcapacity}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Cycle Count</td>
                                                        <td>{device.deviceinfo.devicedetails.cyclecount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Temperature</td>
                                                        <td>{device.deviceinfo.devicedetails.temperature}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Condition</td>
                                                        <td>{device.deviceinfo.devicedetails.condition}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Grade</td>
                                                        <td>{device.deviceinfo.devicedetails.grade}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Comments</td>
                                                        <td>{device.deviceinfo.devicedetails.comments}</td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td className="px-4 align-top">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <tr>
                                                        <td>Proximity</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.proximity ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Gyroscope</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.gyroscope ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Power Button</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.powerbutton ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ultra Wide Camera Quality</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.ultrawidecameraquality ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>NFC</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.nfc ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Earpiece</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.earpiece ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>WiFi</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.wifi ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Accelerometer</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.accelerometer ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rear Camera</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.rearcamera ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Digitizer</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.digitizer ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Volume Down Button</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.volumedownbutton ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rear Mic Quality</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.rearmicquality ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Bottom Mic Quality</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.bottommicquality ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Flash</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.flash ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Front Mic Quality</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.frontmicquality ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Volume Up Button</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.volumeupbuttom ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Bottom Mic</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.bottommic ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rear Mic</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.rearmic ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Front Camera</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.frontcamera ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Screen Rotation</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.screenrotation ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Device Vibration</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.devicevibration ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Front Camera Quality</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.frontcameraquality ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Bluetooth</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.bluetooth ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Loud Speaker</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.loudspeaker ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Flip</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.flip ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Comments Functional</td>
                                                        <td>{device.deviceinfo.functionaldiagnostics.commentsfunctional}</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h4>Cosmetics Details</h4>
                                            </td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td className="align-top">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <tr>
                                                        <td>Water Proof</td>
                                                        <td>{device.deviceinfo.cosmeticsdetails.waterproof ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>OEM LCD</td>
                                                        <td>{device.deviceinfo.cosmeticsdetails.oemlcd ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GEN LCD</td>
                                                        <td>{device.deviceinfo.cosmeticsdetails.genlcd ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Front Glass Change</td>
                                                        <td>{device.deviceinfo.cosmeticsdetails.frontglasschange ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Front Glass Buff</td>
                                                        <td>{device.deviceinfo.cosmeticsdetails.frontglassbuff ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Back Cover Change</td>
                                                        <td>{device.deviceinfo.cosmeticsdetails.backcoverchange ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Back Cover Buff</td>
                                                        <td>{device.deviceinfo.cosmeticsdetails.backcoverbuff ? "Yes" : "No"}</td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td className="px-4 align-top">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <tr>
                                                        <td>Back Cover With Left Dent Mark</td>
                                                        <td>{device.deviceinfo.cosmeticsdetailsmm.backcoverwithleftdentmark}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Dent On Charging Port</td>
                                                        <td>{device.deviceinfo.cosmeticsdetailsmm.dentonchargingport}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Scratch On Front Glass</td>
                                                        <td>{device.deviceinfo.cosmeticsdetailsmm.scratchonfrontglass}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Scratch On Back Glass</td>
                                                        <td>{device.deviceinfo.cosmeticsdetailsmm.scratchonbackglass}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Scratch On Back Camera</td>
                                                        <td>{device.deviceinfo.cosmeticsdetailsmm.scratchonbackcamera}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Dot in LCD</td>
                                                        <td>{device.deviceinfo.cosmeticsdetailsmm.dotinlcd}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Dot in Camera 1</td>
                                                        <td>{device.deviceinfo.cosmeticsdetailsmm.dotincamera1}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Dot in Camera 2</td>
                                                        <td>{device.deviceinfo.cosmeticsdetailsmm.dotincamera2}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Dot in Camera 3</td>
                                                        <td>{device.deviceinfo.cosmeticsdetailsmm.dotincamera3}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Comments Cosmetics</td>
                                                        <td>{device.deviceinfo.cosmeticsdetailsmm.commentscosmetics}</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                :
                                <div>Device not found</div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeviceDetails;