import React, { useState } from 'react';
import Sidebar from '../components/sidebar';
import Header from '../components/header';

const SendRequest = () => {

    const generateDeviceId = () => {
        const timestamp = new Date().getTime().toString(36);
        const randomPart = Math.random().toString(36).substr(2, 5);
        return `${timestamp}_${randomPart}`;
    };

    const [devices, setDevices] = useState([
        {
            id: generateDeviceId(),
            imei1: '',
            imei2: '',
            modelname: '',
            modelno: '',
            color: '',
            memory: '',
            serial: '',
            collapsed: false,
        },
    ]);

    const addDevice = () => {
        setDevices([
            ...devices,
            {
                id: generateDeviceId(),
                imei1: '',
                imei2: '',
                modelname: '',
                modelno: '',
                color: '',
                memory: '',
                serial: '',
                collapsed: false,
            },
        ]);
    };

    const deleteDevice = (index) => {
        if (devices.length > 1) {
            const updatedDevices = devices.filter((_, i) => i !== index);
            setDevices(updatedDevices);
        }
    };

    const handleInputChange = (index, key, value) => {
        const updatedDevices = [...devices];
        updatedDevices[index][key] = value;
        setDevices(updatedDevices);
    };

    const toggleCollapse = (index) => {
        const updatedDevices = devices.map((device, i) => ({
            ...device,
            collapsed: i === index ? !device.collapsed : true,
        }));
        setDevices(updatedDevices);
    };


    const token = window.localStorage.getItem('token');
    console.log(token);

    const submitRequest = async () => {
        try {
            // Check if at least one field is filled in any device
            const isValid = devices.every((device) => (
                device.imei1 && device.imei2 && device.modelname &&
                device.modelno && device.color && device.memory &&
                device.serial
            ));

            if (!isValid) {
                alert('Fill all device details before submitting.');
                return;
            }

            // Add unique IDs for each device
            const devicesWithIds = devices.map((device) => ({
                deviceinfo: {
                    devicedetails: {
                        ...device,
                    }
                },
            }));

            console.log('Sending request:', devicesWithIds);

            // Use localStorage instead of SecureStore
            const token = window.localStorage.getItem('token');
            console.log(token);

            // Perform the fetch request to your backend API
            // Note: Adjust the URL and headers as needed
            const response = await fetch('https://api.intimeglobal.ae/vendorRequest', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ devices: devicesWithIds }),
            });

            const result = await response.json();
            console.log('Server response:', result);

            if (result.status === "ok") {
                alert(result.message);
                // Use history.push to navigate to 'RequestSuccess' page
                // history.push('/RequestSuccess');
                // window.location.href = "./dashboard";
            }

            // Clear input fields
            const clearedDevices = devices.map(() => ({
                id: generateDeviceId(),
                imei1: '',
                imei2: '',
                modelname: '',
                modelno: '',
                color: '',
                memory: '',
                serial: '',
                collapsed: false,
            }));

            setDevices(clearedDevices);
        } catch (error) {
            console.error('Error submitting request:', error);
        }
    };

    return (
        <div className="wrapper">
            <Sidebar />

            <div id="content">

                <Header />

                <div>
                    <div className="row">
                        <div className="col">
                            <h4 className="pb-3">Send Request</h4>
                        </div>
                        <div className="col text-right">
                            <button
                                className="btn btn-primary mr-3"
                                onClick={addDevice}
                            >
                                Add more device
                            </button>
                        </div>
                    </div>


                    {devices.map((device, index) => (
                        <div key={device.id}>
                            <div className="accordion mb-4" id={`accordion-${device.id}`}>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id={`heading-${device.id}`}>
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse-${device.id}`}
                                            aria-expanded="true"
                                            aria-controls={`collapse-${device.id}`}
                                            onClick={() => toggleCollapse(index)}
                                        >
                                            {device.imei1 ? device.imei1 : 'Enter Device Details'}
                                        </button>
                                    </h2>
                                    <div
                                        id={`collapse-${device.id}`}
                                        className={`accordion-collapse collapse${device.collapsed ? '' : ' show'}`}
                                        aria-labelledby={`heading-${device.id}`}
                                        data-bs-parent={`#accordion-${device.id}`}
                                    >
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="IMEI 1"
                                                        value={device.imei1}
                                                        onChange={(e) => handleInputChange(index, 'imei1', e.target.value)}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder='IMEI 2'
                                                        value={device.imei2}
                                                        onChange={(e) => handleInputChange(index, 'imei2', e.target.value)}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder='Model Name'
                                                        value={device.modelname}
                                                        onChange={(e) => handleInputChange(index, 'modelname', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row pt-4">
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder='Model No'
                                                        value={device.modelno}
                                                        onChange={(e) => handleInputChange(index, 'modelno', e.target.value)}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder='Color'
                                                        value={device.color}
                                                        onChange={(e) => handleInputChange(index, 'color', e.target.value)}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder='Memory'
                                                        value={device.memory}
                                                        onChange={(e) => handleInputChange(index, 'memory', e.target.value)}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder='Serial No.'
                                                        value={device.serial}
                                                        onChange={(e) => handleInputChange(index, 'serial', e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col text-right">
                                                    {index > 0 && (
                                                        <button
                                                            className="btn btn-danger"
                                                            onClick={() => deleteDevice(index)}
                                                        >
                                                            <i className="fas fa-trash"></i>
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="row">
                        <div className="col text-right pt-4">
                            <button
                                className="btn btn-success w-100"
                                onClick={submitRequest}
                            >
                                Submit Request
                            </button>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    )
}

export default SendRequest
