import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Home = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://api.intimeglobal.ae/login', formData);

            if (response.data.status === "ok") {
                console.log(response.data);
                alert('Login Successful', 'success');
                window.localStorage.setItem("token", response.data.token);
                window.localStorage.setItem("loggedIn", true);
                window.location.href = "/";
            } else {
                alert('Login Error: ' + response.data.message, 'danger');
            }
        } catch (error) {
            console.error('Error during login:', error.message);
            alert('Login Error', 'danger');
        }
    };

    return (
        <div className="container h-100">
            <div className="d-flex justify-content-center h-100">
                <div className="user_card">
                    <div className="d-flex justify-content-center">
                        <div className="brand_logo_container">
                            <img src="../../intime-logo.png" className="brand_logo" alt="Intime Smart" />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center form_container">
                        <form onSubmit={handleSubmit}>
                            <div className="input-group mb-3">
                                <div className="input-group-append">
                                    <span className="input-group-text"><i className="fas fa-user"></i></span>
                                </div>
                                <input type="text" id="email" className="form-control input_user" value={formData.email}
                                    onChange={handleChange} placeholder="Enter Email" required />
                            </div>
                            <div className="input-group mb-2">
                                <div className="input-group-append">
                                    <span className="input-group-text"><i className="fas fa-key"></i></span>
                                </div>
                                <input
                                    type="password"
                                    className="form-control input_user"
                                    placeholder="Password"
                                    id="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="d-flex justify-content-center mt-3 login_container">
                                <button type="submit" name="button" className="btn login_btn">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default Home;
